import React from "react";
import { Row, Col } from "react-bootstrap";
import Api from "../../api/Api";

import "./ContactUs.css";
export default function ContactUs() {
  function openTermsConditions() {
    Api.getTermsAndConditions().then((res) => {
      let terms = res.data;
      window.open(terms.url, "_blank");
    });
  }

  return (
    <>
      <Row>
        <Col className="heading heading-font">
          Confident Asset Management Limited
        </Col>
      </Row>
      <Row className="paragraph-font align-items-vertical-center add-top-margin">
        <Col xs={4} className="icon-size">
          <i className="fa fa-map-marker" aria-hidden="true"></i>
        </Col>
        <Col xs={8}>
          <Row>
            <Col>The Country Side, 1st Floor</Col>
          </Row>
          <Row>
            <Col>Vivéa Business Park, St Pierre</Col>
          </Row>
          <Row>
            <Col>Republic of Mauritius</Col>
          </Row>
        </Col>
      </Row>
      <Row className="paragraph-font align-items-vertical-center">
        <Col xs={4} className="icon-size">
          <i className="fa fa-phone" aria-hidden="true"></i>
        </Col>
        <Col xs={8}>
          <a href="tel:(230) 402 3520">(230) 402 3520</a>
        </Col>
      </Row>
      <Row className="paragraph-font align-items-vertical-center">
        <Col xs={4} className="icon-size">
          <i className="fa fa-envelope" aria-hidden="true"></i>
        </Col>
        <Col xs={8}>
          <a href="mailto:info@confident.mu">info@confident.mu</a>
        </Col>
      </Row>
      <Row className="paragraph-font align-items-vertical-center">
        <Col xs={4} className="icon-size">
          <i className="fa fa-desktop" aria-hidden="true"></i>
        </Col>
        <Col xs={8}>
          <a href="https://www.confident.mu" target="_blank">
            www.confident.mu
          </a>
        </Col>
      </Row>
      <Row className="paragraph-font align-items-vertical-center">
        <Col xs={4} className="icon-size">
          <i className="fa fa-file-text" aria-hidden="true"></i>
        </Col>
        <Col xs={8} onClick={openTermsConditions}>
          <a className="info-link">Terms and Conditions</a>
        </Col>
      </Row>
    </>
  );
}
